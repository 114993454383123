.filterButtonContainer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    visibility: visible;
}

.filterButton {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #0077cc;
    color: white;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
}

.filterButton:hover {
    background-color: #005fa3;
    transform: scale(1.05);
}

.filterCount {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #e74c3c;
    color: white;
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupContainer {
    position: absolute;
    bottom: 60px;
    right: 0;
    width: 280px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    animation: popupFadeIn 0.2s ease;
}

@keyframes popupFadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.popupHeader {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    background-color: #f8f9fa;
}

.popupTitle {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.popupContent {
    padding: 16px;
    max-height: 300px;
    overflow-y: auto;
}

.storeChips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.storeChip {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border-radius: 16px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.storeChip.active {
    background-color: #e8f4ff;
    border: 1px solid #0077cc;
    color: #0077cc;
}

.storeChip.inactive {
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    color: #666;
}

.storeChip:hover {
    transform: scale(1.05);
}

.storeChip.active:hover {
    background-color: #d1e9ff;
}

.storeChip.inactive:hover {
    background-color: #ebebeb;
}

.storeName {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.removeIndicator {
    margin-left: 6px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addIndicator {
    margin-left: 6px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noFilters {
    font-size: 13px;
    color: #999;
    font-style: italic;
    text-align: center;
    padding: 10px 0;
}